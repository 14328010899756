<template>
  <section class="global-simple-list">
    <ul class="clebex-item-section">
      <li
        v-for="(item, i) in data"
        :key="i"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{ item.title }}</label>
            <div>
              <p class="text-label">{{ item.content }}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "SimpleList",
  props: {
    data: {
      required: true,
      type: Array
    }
  }
};
</script>
